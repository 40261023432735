import { isNil } from "lodash";
import { roundFte } from "src/util/roundingStrategy";

export const calculateDelta = (value, defaultedValue) => {
  return isNil(value) ? 0 : roundFte(value - defaultedValue);
};

export const calculateDeltaWithRealtimeFte = (
  value,
  realtimeCurrentMemberFte
) => {
  // Realtime current member fte becomes the baseline
  const delta =
    isNil(value) || isNil(realtimeCurrentMemberFte)
      ? 0
      : roundFte(value - realtimeCurrentMemberFte);

  return delta;
};

export const calculateRealtimeChangeOnly = (
  defaultedValue,
  realtimeCurrentMemberFte
) => {
  // Only calculate the FTE changes of the team
  // realtimeCurrentMemberFte - defaultedValue(allocatedMemberFte)
  const delta =
    isNil(defaultedValue) || isNil(realtimeCurrentMemberFte)
      ? 0
      : roundFte(realtimeCurrentMemberFte - defaultedValue);

  return delta;
};

export const calculateRealtimeCurrentDelta = (
  value,
  realtimeCurrentMemberFte,
  defaultedValue
) => {
  // For realtime current member fte edit, need to find the member fte change first
  // Then add the desired delta value as delta
  const memberFteChange = isNil(realtimeCurrentMemberFte)
    ? 0
    : realtimeCurrentMemberFte - defaultedValue;

  const desiredDelta = isNil(value) ? 0 : value - defaultedValue;

  return roundFte(desiredDelta + memberFteChange);
};

export const calculateCellDelta = ({
  isRealtimeFteMode,
  useRealtimeFteDelta,
  useRealtimeChangeOnly,
  value,
  realtimeCurrentMemberFte,
  defaultedValue,
}) => {
  if (useRealtimeChangeOnly) {
    return calculateRealtimeChangeOnly(
      defaultedValue,
      realtimeCurrentMemberFte
    );
  }

  // When using the macro allocation input as minus delta, needs to use a different way to calculate
  // first need to calculate the member changes: realtimeCurrentMemberFte - defaultedValue
  // then need to add the desired change: value - defaultedValue
  if (useRealtimeFteDelta) {
    return calculateRealtimeCurrentDelta(
      value,
      realtimeCurrentMemberFte,
      defaultedValue
    );
  }

  // When turning on the flag enableMemberFteEdit, isRealtimeFteMode is true,
  // user can edit the member fte directly
  // in this case, we'll use realtime member fte as baseline for the calculation
  // Value will be the desired team fte
  if (isRealtimeFteMode) {
    return calculateDeltaWithRealtimeFte(value, realtimeCurrentMemberFte);
  }

  // Normal way of calculating delta: value - defaultedValue (allocatedMemberFte)
  return calculateDelta(value, defaultedValue);
};
