import { TAG_ENTITY_PERSON_TYPE, TAG_ENTITY_TEAM_TYPE } from "src/consts/tags";

const shouldHighlightTag = ({ entityType, tag }) => {
  if (entityType === TAG_ENTITY_TEAM_TYPE && tag?.tagTypeConfig?.isGroupBadge) {
    return true;
  }
  if (
    entityType === TAG_ENTITY_PERSON_TYPE &&
    tag?.tagTypeConfig?.isPersonBadge
  ) {
    return true;
  }
  return false;
};

export { shouldHighlightTag };
