import { forEach, get, isEmpty, isNil, map, reduce, isFinite } from "lodash";
import { roundFte } from "src/util/roundingStrategy";
import {
  DIRECT,
  INDIRECT_PLACEHOLDER,
} from "src/allocation/pages/ForecastPage/context/reducer/macroAllocationTypes";
import { getLineItemsWithType } from "src/allocation/pages/ForecastPage/context/reducer/modelReducer/util/lineItemsWithType";
import { getLineItemsTotalCost } from "../setTotalCost";
import {
  PLANNER_APPROVED,
  PLANNER_CURRENT,
  PLANNER_REQUESTED,
} from "../../../columnTypes";

const calculateConstraintTotals = (constraints, columns) =>
  reduce(
    columns,
    (totals, { id: columnId, columnType }, index) => {
      if (constraints[index]) {
        const { constraint, defaultedConstraint } = constraints[index];
        const isConstraintPresent = !isNil(constraint);
        const isPreviousConstraintTotalAllDefaults = get(
          totals[index - 1],
          "isConstraintTotalAllDefaults",
          true
        );
        return [
          ...totals,
          {
            columnId,
            columnType,
            constraintTotal: isConstraintPresent
              ? constraint
              : defaultedConstraint,
            isConstraintTotalAllDefaults:
              isPreviousConstraintTotalAllDefaults && !isConstraintPresent,
          },
        ];
      }

      return [
        ...totals,
        {
          columnId,
          columnType,
          constraintTotal: 0,
          isConstraintTotalAllDefaults: true,
        },
      ];
    },
    []
  );

const zeroedLineItemTotals = (columns) =>
  map(columns, ({ id, columnType }) => ({
    columnId: id,
    columnType,
    total: 0,
    deltaTotal: 0,
    totalCost: 0,
  }));

const calculateLineItemTotals = (lineItems, columns) =>
  map(
    reduce(
      lineItems,
      (totals, lineItem) => {
        return map(
          totals,
          ({ columnId, columnType, total, deltaTotal, totalCost }, index) => {
            const {
              value,
              defaultedValue,
              delta,
              totalCost: cellTotalCost,
            } = lineItem.cells[index];
            return {
              columnId,
              columnType,
              total: total + (!isNil(value) ? value : defaultedValue),
              deltaTotal: deltaTotal + (!isNil(delta) ? delta : 0),
              totalCost:
                totalCost + (isFinite(cellTotalCost) ? cellTotalCost : 0),
            };
          }
        );
      },
      zeroedLineItemTotals(columns)
    ),
    ({ columnId, columnType, total, deltaTotal, totalCost }) => ({
      columnId,
      columnType,
      total: roundFte(total),
      deltaTotal: roundFte(deltaTotal),
      totalCost,
    })
  );

const calculateLineItemTotalsByType = (lineItems, columns) => {
  const lineItemTotalsByType = {};
  const indirectPlaceholderLineItems = getLineItemsWithType(
    lineItems,
    INDIRECT_PLACEHOLDER
  );
  lineItemTotalsByType[INDIRECT_PLACEHOLDER] = calculateLineItemTotals(
    indirectPlaceholderLineItems,
    columns
  );
  lineItemTotalsByType[`totalCost-${INDIRECT_PLACEHOLDER}`] =
    getLineItemsTotalCost(indirectPlaceholderLineItems);

  const defaultLineItems = getLineItemsWithType(lineItems, null);
  lineItemTotalsByType[DIRECT] = calculateLineItemTotals(
    defaultLineItems,
    columns
  );
  lineItemTotalsByType[`totalCost-${DIRECT}`] =
    getLineItemsTotalCost(lineItems);

  return lineItemTotalsByType;
};

const zeroedGroupingsTotal = (columns) =>
  map(columns, ({ id, columnType }) => ({
    columnId: id,
    columnType,
    total: 0,
    deltaTotal: 0,
    totalCost: 0,
    constraintTotal: 0,
    isConstraintTotalAllDefaults: true,
  }));

const calculateChildGroupTotals = (childGroupings, columns) =>
  map(
    reduce(
      childGroupings,
      (totals, grouping) => {
        return map(
          totals,
          (
            {
              columnId,
              columnType,
              total,
              deltaTotal,
              totalCost,
              constraintTotal,
              isConstraintTotalAllDefaults,
            },
            index
          ) => {
            const {
              total: subTotal,
              deltaTotal: deltaSubTotal,
              totalCost: subTotalCost,
              constraintTotal: constraintSubTotal,
              isConstraintTotalAllDefaults: isConstraintTotalAllDefaultsRHS,
            } = grouping.summedTotals[index];
            return {
              columnId,
              columnType,
              total: total + subTotal,
              deltaTotal: deltaTotal + deltaSubTotal,
              totalCost: totalCost + subTotalCost,
              constraintTotal: constraintTotal + constraintSubTotal,
              isConstraintTotalAllDefaults:
                isConstraintTotalAllDefaults && isConstraintTotalAllDefaultsRHS,
            };
          }
        );
      },
      zeroedGroupingsTotal(columns)
    ),
    ({
      columnId,
      columnType,
      total,
      deltaTotal,
      totalCost,
      constraintTotal,
      isConstraintTotalAllDefaults,
    }) => ({
      columnId,
      columnType,
      total: roundFte(total),
      deltaTotal: roundFte(deltaTotal),
      totalCost,
      constraintTotal: roundFte(constraintTotal),
      isConstraintTotalAllDefaults,
    })
  );

const setGroupingWithCalculatedSumTotals = (
  grouping,
  columns,
  { recalcChildren = true, recalcParents = false } = {}
) => {
  if (recalcChildren && !isEmpty(grouping.childGroupings)) {
    forEach(grouping.childGroupings, (childGrouping) => {
      setGroupingWithCalculatedSumTotals(childGrouping, columns);
    });
  }

  const constraintTotals = calculateConstraintTotals(
    grouping.constraints,
    columns
  );
  const lineItemTotals = calculateLineItemTotals(grouping.lineItems, columns);
  const lineItemTotalsByType = calculateLineItemTotalsByType(
    grouping.lineItems,
    columns
  );
  const childGroupTotals = calculateChildGroupTotals(
    grouping.childGroupings,
    columns
  );

  // eslint-disable-next-line no-param-reassign
  grouping.lineItemTotals = lineItemTotals;
  // eslint-disable-next-line no-param-reassign
  grouping.lineItemTotalsByType = lineItemTotalsByType;

  // eslint-disable-next-line no-param-reassign
  grouping.summedTotals = map(columns, ({ id, columnType }, index) => {
    const lineItemTotalByColumn = lineItemTotals[index];
    const childGroupsTotalByColumn = childGroupTotals[index];
    let total = roundFte(
      lineItemTotalByColumn.total + childGroupsTotalByColumn.total
    );
    let deltaTotal = roundFte(
      lineItemTotalByColumn.deltaTotal + childGroupsTotalByColumn.deltaTotal
    );

    // adjust totals for moved-in and moved-out groupings
    if (grouping.isMovedIn) {
      if (columnType === PLANNER_CURRENT) {
        // we start at 0, because the grouping is moved in
        total = 0;
      }
      if (columnType === PLANNER_REQUESTED) {
        // deltaTotal = 0 + total = total
        // deltaTotal = total;
      }
    }
    if (grouping.isMovedOut) {
      if (grouping.isMovedInternally) {
        // if the grouping is moved out and internally moved,
        // the totals are already calculated from the copied moved-in grouping
        if (columnType === PLANNER_CURRENT) {
          // total is already calculated from the copied moved-in grouping
          total = grouping.summedTotals[0].total;
        }
        if (columnType === PLANNER_REQUESTED) {
          // total is 0 because the grouping is moved out
          total = 0;

          // deltaTotal is the negative of the current FTE in the grouping
          // copied from the pre-calculated moved-in grouping
          deltaTotal = -grouping.summedTotals[0].total;
        }
        if (columnType === PLANNER_APPROVED) {
          // total is 0 because the grouping is moved out
          total = 0;

          // deltaTotal is the negative of the current FTE in the grouping
          // copied from the moved-in grouping
          deltaTotal = -grouping.summedTotals[0].total;
        }
      }
    }

    return {
      columnId: id,
      columnType,
      total,
      deltaTotal,
      totalCost:
        lineItemTotalByColumn.totalCost + childGroupsTotalByColumn.totalCost,
      constraintTotal: !isEmpty(grouping.childGroupings)
        ? childGroupsTotalByColumn.constraintTotal
        : constraintTotals[index].constraintTotal,
      isConstraintTotalAllDefaults: !isEmpty(grouping.childGroupings)
        ? childGroupsTotalByColumn.isConstraintTotalAllDefaults
        : constraintTotals[index].isConstraintTotalAllDefaults,
    };
  });

  if (recalcParents && grouping.parent) {
    setGroupingWithCalculatedSumTotals(grouping.parent, columns, {
      recalcChildren: false,
      recalcParents: true,
    });
  }
};

export default setGroupingWithCalculatedSumTotals;
