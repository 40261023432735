import React from "react";
import { Box, Flex, Popover } from "orcs-design-system";
import styled from "styled-components";
import PropTypes from "prop-types";

const VerticalDots = styled.div`
  &:hover {
    cursor: pointer;
  }
  &::after {
    content: "\uFE19";
    font-size: 14px;
    display: block;
    text-align: center;
    font-weight: 700;
  }
`;

const StyledP = styled.p`
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  padding: 0.5rem;
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const ActionMenu = ({
  handleClickToTheTop,
  // handleClickHighlightInVisualiser,
}) => {
  return (
    <Box>
      <Popover
        direction="top"
        width="200px"
        textAlign="center"
        text={
          <Flex
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            padding="s"
          >
            {/* This will be enabled when strategy view is implemented.. */}
            {/* <StyledP onClick={handleClickHighlightInVisualiser}>
              Highlight in visualiser
            </StyledP> */}
            <StyledP onClick={handleClickToTheTop}>Pin to the top</StyledP>
          </Flex>
        }
      >
        <VerticalDots />
      </Popover>
    </Box>
  );
};

ActionMenu.propTypes = {
  handleClickToTheTop: PropTypes.func.isRequired,
  // handleClickHighlightInVisualiser: PropTypes.func.isRequired,
};

export default ActionMenu;
