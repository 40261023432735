import React, { useCallback } from "react";
import { debounce, get, pick, set } from "lodash";
import PropTypes from "prop-types";
import { Select, Spacer, Toggle } from "orcs-design-system";
import {
  useFilters,
  useApplyFilters,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import FilterOptionPropType from "../../../custom-prop-types/filterOption";
import formatOptionLabel from "./formatOptionLabel";

const FilterSelect = (props) => {
  const {
    onInputChange,
    isSearching,
    options,
    valueKey,
    toggles,
    name,
    filterOption,
    backspaceRemovesValue,
    ...selectProps
  } = props;

  const filters = useFilters();

  const applyFilters = useApplyFilters();
  const filterValue = get(filters, valueKey, []);

  const onFilterValueChange = useCallback(
    (key, value) => {
      const newValue = pick(filters, name.split(".")[0]); // Handle team.* filters

      set(newValue, key, value);

      applyFilters(newValue);
    },
    [filters, name, applyFilters]
  );

  const select = (
    <Select
      placeholder="Search or select"
      {...selectProps}
      options={options}
      value={filterValue}
      filterOption={filterOption}
      onChange={(value) => onFilterValueChange(valueKey, value)}
      onInputChange={
        onInputChange && debounce(onInputChange, DEBOUNCE_SEARCH_TIME)
      }
      backspaceRemovesValue={backspaceRemovesValue}
      isLoading={isSearching}
      formatOptionLabel={formatOptionLabel}
    />
  );

  if (!toggles.length) return select;

  return (
    <Spacer mb="s">
      {select}

      {toggles.map((toggle) => (
        <Toggle
          key={toggle.id}
          small
          id={toggle.id}
          label={toggle.label}
          className={`guided-tour-filter-toggle-${toggle.id}`}
          checked={get(filters, toggle.valueKey)}
          onChange={(e) =>
            onFilterValueChange(toggle.valueKey, e.target.checked)
          }
        />
      ))}
    </Spacer>
  );
};

FilterSelect.defaultProps = {
  isSearching: false,
  filterOption: false,
  backspaceRemovesValue: false,
  toggles: [],
};

FilterSelect.propTypes = {
  options: PropTypes.arrayOf(FilterOptionPropType).isRequired,
  onInputChange: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  valueKey: PropTypes.string.isRequired,
  toggles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      valueKey: PropTypes.string.isRequired,
    })
  ),
  name: PropTypes.string.isRequired,
  filterOption: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
};

export default FilterSelect;
