import React from "react";
import { Box, Card, Divider, Flex, H5, P, StatusDot } from "orcs-design-system";
import { isEmpty, map } from "lodash";
import PropTypes from "prop-types";
import NotificationDot from "../NotificationDot";
import ActionMenu from "../actionMenu";

const InsightPanel = ({ insight, handleClickToTheTop }) => {
  if (isEmpty(insight)) {
    return null;
  }
  return (
    <Card>
      <Flex flexDirection="column">
        <Flex
          mb="s"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Flex flexDirection="row" alignItems="center">
            <StatusDot variant="warning" mr="s" />
            <H5 fontWeight={600}>{insight.displayName}</H5>
          </Flex>
          <ActionMenu
            handleClickToTheTop={() => {
              handleClickToTheTop(insight.type);
            }}
          />
        </Flex>
        <Divider light />
        <Flex mt="s" mb="s">
          <small>{insight?.description}</small>
        </Flex>
        <Flex flexDirection="column">
          {map(insight.data, (data, index) => {
            return (
              <Box
                bg={index === 0 ? "#f2f2f2" : "transparent"}
                key={index}
                pl={index === 0 ? "3" : "4"}
                pr="3"
              >
                <Flex mb="xs" mt="s" justifyContent="space-between">
                  <P fontSize="1.2rem">{data?.group.name}</P>
                  <Flex
                    justifyContent={
                      data?.attributes?.display ? "space-between" : "end"
                    }
                    alignItems="center"
                  >
                    <NotificationDot
                      met={data?.met}
                      detail={data?.detail}
                      ruleDetail={data?.ruleDetail}
                    />
                    <P fontSize="1.2rem" pl="s">
                      {data.value}
                      {insight?.measureType === "percentage" ? "%" : ""}
                    </P>
                  </Flex>
                </Flex>
                <Divider dash light />
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </Card>
  );
};

InsightPanel.propTypes = {
  insight: PropTypes.objectOf({
    displayName: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.arrayOf({
      group: PropTypes.objectOf({
        name: PropTypes.string,
      }),
      value: PropTypes.number,
    }),
    measureType: PropTypes.string,
  }),
  handleClickToTheTop: PropTypes.func,
};

export default React.memo(InsightPanel);
