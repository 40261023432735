import React, { useState, useCallback, useMemo } from "react";
import {
  isEqual,
  get,
  filter,
  size,
  reduce,
  map,
  keys,
  omit,
  cloneDeep,
} from "lodash";
import PropTypes from "prop-types";

import { useAuth } from "src/contexts/global/GlobalContext";
import { useTenantId } from "src/contexts/global/ApolloSettingsContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import {
  getFiltersFromLocalStorage,
  getFTEFromLocalStorage,
  deleteFiltersFromLocalStorage,
  saveFiltersToLocalStorage,
  saveFTEToLocalStorage,
} from "src/services/localStorage";
import {
  Provider,
  DEFAULT_FILTERS,
  DEFAULT_FTE_MODE,
} from "./FilterAndFteContext";

const getActiveFilterCount = (filterItems) => {
  const filterSize = size(
    filter(
      omit(filterItems, "teams"),
      (filterItem, key) => !isEqual(filterItem, get(DEFAULT_FILTERS, key))
    )
  );

  const teamFilterSize = size(
    filter(
      filterItems.teams,
      (filterItem, key) => !isEqual(filterItem, get(DEFAULT_FILTERS.teams, key))
    )
  );

  return filterSize + teamFilterSize;
};

const mapValue = (raw) => map(raw, "value");

const convertFilters = (filters) => {
  const convertedFilters = reduce(
    filters,
    (prev, rawValue, key) => {
      if (key === "teams") {
        // eslint-disable-next-line no-param-reassign
        prev[key] = {
          ...rawValue,
          teams: mapValue(rawValue.teams),
          types: mapValue(rawValue.types),
        };
      } else if (key === "divisions") {
        // eslint-disable-next-line no-param-reassign
        prev[key] = {
          ...rawValue,
          divisions: mapValue(rawValue.divisions),
        };
      } else {
        // eslint-disable-next-line no-param-reassign
        prev[key] = mapValue(rawValue);
      }

      return prev;
    },
    {}
  );
  return convertedFilters;
};

// React-Select is expecting new format of options [{ vlaue, label }],
// But former saved filters may be the format ["value"].
// Add a check for it, can be removed when all UI version updated => confirm from Analytics
const isOldFilterValues = (filters) => {
  const targets = keys(filters);
  for (let i = 0, j = targets.length; i < j; i += 1) {
    const key = targets[i];
    let values = null;
    if (key === "teams") {
      values = [
        ...get(filters[key], "teams", []),
        ...get(filters[key], "types", []),
      ];
    } else if (key === "divisions") {
      values = get(filters[key], "divisions", []);
    } else {
      values = filters[key] || [];
    }

    const oldValues = filter(values, (v) => !v || !v.value);
    if (oldValues.length > 0) {
      return true;
    }
  }

  return false;
};

const FilterAndFteContextProvider = ({ children }) => {
  const tenantId = useTenantId();
  const workspace = useWorkspace();
  const auth = useAuth();

  const workspaceId = workspace.id;
  const userId = auth.username;

  const initialFilters = useMemo(() => {
    const saved = getFiltersFromLocalStorage(tenantId, workspaceId, userId);

    if (!saved || isOldFilterValues(saved)) {
      return cloneDeep(DEFAULT_FILTERS);
    }

    return saved;
  }, [tenantId, workspaceId, userId]);

  const initialFteMode =
    getFTEFromLocalStorage(tenantId, workspaceId, userId) || DEFAULT_FTE_MODE;

  const [filters, setFilters] = useState(initialFilters);

  const applyFilters = useCallback(
    (updatedFilter) => {
      const updatedFilters = { ...filters, ...updatedFilter };
      setFilters(updatedFilters);
      saveFiltersToLocalStorage(tenantId, workspaceId, userId, updatedFilters);
    },
    [tenantId, workspaceId, userId, filters]
  );

  const clearFilters = useCallback(() => {
    setFilters(cloneDeep(DEFAULT_FILTERS));
    deleteFiltersFromLocalStorage(tenantId, workspaceId, userId);
  }, [tenantId, workspaceId, userId]);

  const activeFilterCount = useMemo(
    () => getActiveFilterCount(filters),
    [filters]
  );

  const queryFilters = useMemo(() => convertFilters(filters), [filters]);

  const [fteMode, setFteMode] = useState(initialFteMode);
  const applyFteMode = useCallback(
    (mode) => {
      setFteMode(mode);
      saveFTEToLocalStorage(tenantId, workspaceId, userId, mode);
    },
    [tenantId, workspaceId, userId]
  );

  const values = {
    filters,
    queryFilters,
    activeFilterCount,
    applyFilters,
    clearFilters,
    fteMode,
    applyFteMode,
  };

  return <Provider {...values}>{children}</Provider>;
};

FilterAndFteContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default FilterAndFteContextProvider;
