import { get, sortBy, orderBy, partition, concat } from "lodash";
import { TAG_OPERATIONS } from "src/consts/tags";
import { shouldHighlightTag } from "../utils/shouldHighlightTag";

export const getSortedTags = (tags) => {
  return orderBy(
    sortBy(tags, [
      (tag) => get(tag, "type", "").toLowerCase(),
      (tag) => get(tag, "displayValue", "").toLowerCase(),
    ])
  );
};

export const getCombinedSortedTags = (tags, entityType) => {
  const [remoavedTags, nonRemovedTags] = partition(
    tags,
    (tag) => tag.status === TAG_OPERATIONS.REMOVED
  );
  const [highlightedTags, nonHighlightedTags] = partition(
    nonRemovedTags,
    (tag) => {
      return shouldHighlightTag({
        entityType,
        tag,
      });
    }
  );

  return concat(
    getSortedTags(highlightedTags),
    getSortedTags(nonHighlightedTags),
    getSortedTags(remoavedTags)
  );
};
