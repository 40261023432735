import { useMemo, useReducer } from "react";
import { isStrategyPage } from "src/util/url";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import {
  strategyInsightsReducer,
  strategyInsightInitialState,
} from "../reducer";
import getOrderedList from "../utils/getOrderedList";
import useInsightsData from "./useInsightsData";

const useStrategyInsights = (match) => {
  const teamId = match?.params?.id;
  const { enableStrategyInsightPanel } = useWorkspaceFeatureFlags();

  const { data } = useInsightsData({
    teamId,
    skip:
      !isStrategyPage(match?.params) || !teamId || !enableStrategyInsightPanel,
  });
  const [state, dispatch] = useReducer(
    strategyInsightsReducer,
    useMemo(() => strategyInsightInitialState, [])
  );

  const orderedInsights = useMemo(() => {
    return getOrderedList({ insightsOrder: state?.insightsOrder, data });
  }, [data, state?.insightsOrder]);

  return {
    strategyInsights: orderedInsights,
    insightsDispatch: dispatch,
    state,
  };
};

export default useStrategyInsights;
