import { calculateTotalCost } from "src/allocation/util/budgetAndCost";

import {
  hashCell,
  setGroupingWithCalculatedSumTotals,
  updateGroupingTotalCost,
} from "./util";
import { calculateCellDelta } from "./util/cellDelta";

const updateModelWithRealTimeMemberFTE = (state, action) => {
  const { cellId, value } = action;
  const {
    rootGroup,
    isRealtimeFteMode,
    useRealtimeChangeOnly,
    useRealtimeFteDelta,
    isBudgetEnabled,
    useDeltaForBudget,
    overBudgetLimit,
    underBudgetLimit,
    dynamicModel: {
      lookups: { cellLookup },
      columns,
    },
  } = state;
  const cell = cellLookup[cellId];

  if (!cell || value === cell.realtimeCurrentMemberFte) {
    return state;
  }

  cell.realtimeCurrentMemberFte = Number(value);
  cell.value = cell.realtimeCurrentMemberFte;

  // After updated the realtime current fte, need to update the delta
  cell.delta = calculateCellDelta({
    isRealtimeFteMode: rootGroup.isDemand && isRealtimeFteMode,
    useRealtimeChangeOnly: rootGroup.isDemand && useRealtimeChangeOnly,
    useRealtimeFteDelta: rootGroup.isDemand && useRealtimeFteDelta,
    value: cell.value,
    realtimeCurrentMemberFte: cell.realtimeCurrentMemberFte,
    defaultedValue: cell.defaultedValue,
  });

  if (isBudgetEnabled) {
    cell.totalCost = calculateTotalCost({
      value: useDeltaForBudget ? cell.delta : cell.value,
      dailyRate: cell.dailyRate,
      workingDays: cell.workingDays,
    });
  }

  if (isBudgetEnabled) {
    updateGroupingTotalCost(cell.lineItem, overBudgetLimit, underBudgetLimit);
  }

  setGroupingWithCalculatedSumTotals(cell.lineItem.grouping, columns, {
    recalcChildren: false,
    recalcParents: true,
  });

  hashCell(cell, { rehashParents: true });

  return {
    ...state,
  };
};

export default updateModelWithRealTimeMemberFTE;
