import React from "react";
import { StatusDot, Popover } from "orcs-design-system";
import PropTypes from "prop-types";
import { principleStatusText } from "src/components/PrincipleStatus";

const NotificationDot = ({ met, detail, ruleDetail }) => {
  if (!met) {
    return null;
  }

  let Dot = null;

  if (met === "TRUE") {
    Dot = <StatusDot variant="success" />;
  } else if (met === "FALSE") {
    Dot = <StatusDot variant="danger" />;
  }

  if (!detail) {
    return Dot;
  }

  return (
    <Popover
      key={detail}
      direction="right"
      text={principleStatusText(
        {
          title: detail,
          met,
          ruleDetail,
        },
        true
      )}
      textAlign="left"
      width="300px"
    >
      {Dot}
    </Popover>
  );
};

NotificationDot.propTypes = {
  met: PropTypes.string,
  detail: PropTypes.string,
  ruleDetail: PropTypes.string,
};

export default NotificationDot;
