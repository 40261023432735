import React, { useCallback } from "react";
import { Button, H5, Divider, Spacer, Flex, Icon } from "orcs-design-system";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
import {
  useActiveFilterCount,
  useClearFilters,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import useGuidedTourTrigger from "src/guidedTour/useGuidedTourTrigger";
import useTourForRouteMatch from "src/guidedTour/useTourForRouteMatch";
import { useSidebarContext, ACTIONS } from "../../context";

import { FILTER_CONFIGS } from "./filterConfigs";
import FilterSelect from "./FilterSelect";

const FilterSidebarContent = () => {
  const activeFilterCount = useActiveFilterCount();
  const clearFilters = useClearFilters();
  const triggerGuidedTour = useGuidedTourTrigger();
  const tourName = useTourForRouteMatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showGuidedTourButton = !queryParams.has("guided_tour");

  const [{ filterItems, query }, dispatch] = useSidebarContext();

  const isFilterLoading = useCallback(
    (filterKey) => {
      return query.loading && query.variables.searchTermsKey === filterKey;
    },
    [query]
  );
  const onInputChange = useCallback(
    (key, value) => {
      dispatch({ type: ACTIONS.FILTER_ITEM_USER_SEARCH, key, value });
      trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
        setting: "summary_filter_changed",
        value: key,
      });
    },
    [dispatch]
  );

  const filters = [
    FILTER_CONFIGS.DIVISIONS,
    FILTER_CONFIGS.COMPANY,
    FILTER_CONFIGS.LOCATION,
    FILTER_CONFIGS.COUNTRY,
    FILTER_CONFIGS.JOBS,
    FILTER_CONFIGS.TEAM_TYPES,
    FILTER_CONFIGS.TEAMS,
  ];

  return (
    <div className="guided-tour-filter-sidebar-content">
      <Flex alignItems="center">
        <H5 fontWeight="bold" mr="r">
          Filter by
        </H5>

        {showGuidedTourButton && (
          <Button
            small
            iconLeft
            variant="successAlternate"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              triggerGuidedTour(tourName);
            }}
          >
            <Icon icon={["fas", "play-circle"]} mr="s" />
            Guided Tour
          </Button>
        )}
      </Flex>
      <Spacer my="r">
        <Divider light />

        {filters.map((filter) => {
          const remoteSearchProps = {
            onInputChange: (value, { action, prevInputValue }) => {
              if (action === "input-change" && prevInputValue !== value) {
                onInputChange(filter.name, value);
              }
            },
            isSearching: isFilterLoading(filter.name),
          };

          return (
            <FilterSelect
              key={filter.name}
              className={`guided-tour-filter-${filter.name
                .toLowerCase()
                .replace(/\./g, "-")}`}
              options={get(filterItems, filter.optionsKey || filter.name, [])}
              {...(filter.isRemoteSearch ? remoteSearchProps : {})}
              {...filter}
            />
          );
        })}

        <Button
          small
          className="guided-tour-filter-clear-btn"
          onClick={() => {
            clearFilters();
            trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
              setting: "summary_filter_clear_all",
              value: true,
            });
          }}
          variant={activeFilterCount !== 0 ? "default" : "disabled"}
        >
          Clear All
        </Button>
      </Spacer>
    </div>
  );
};

export default FilterSidebarContent;
