import { get } from "lodash";
import {
  getPersonPageTitleQuery,
  getTeamTitleQuery,
  getTagPageTitleQuery,
} from "src/queries/pageTitle.graphql";
import { getName } from "src/util/person";
import { groupTypeAsText } from "src/util/groupType";

export const PAGE_TITLE_CONFIGS = {
  personDetailPage: {
    query: getPersonPageTitleQuery,
    mapper: (data, id) => (!data ? `Person ${id}` : getName(data?.person)),
  },
  teamDetailPage: {
    query: getTeamTitleQuery,
    mapper: (data, id, groupTypes) => {
      const teamType = get(groupTypes, data?.team?.type, {
        name: data?.team?.type,
        noConfig: true,
      });
      return !data
        ? `Team ${id}`
        : `${data?.team?.name} [${groupTypeAsText(teamType)}]`;
    },
  },
  tagDetailPage: {
    query: getTagPageTitleQuery,
    mapper: (data, id) =>
      !data
        ? `Tag ${id}`
        : `${data?.tag[0]?.displayValue} [${data?.tag[0]?.type}]`,
  },
  dashboard: {
    query: getPersonPageTitleQuery,
    mapper: (data) => getName(data),
    isDashboard: true,
  },
};
