import {
  Button,
  Flex,
  Icon,
  Notification,
  Popover,
  Spacer,
} from "orcs-design-system";
import React, { useState } from "react";
import styled from "styled-components";

import { PropTypes } from "prop-types";
import PersonPropType from "src/custom-prop-types/person";
import {
  getPersonEmailAttribute,
  getPersonMobileNumberAttribute,
  getPersonTelephoneAttribute,
  onPersonEmailClick,
  onPersonTelephoneClick,
} from "src/util/person";

import icons from "src/config/icons";
import { useGlobalConfig } from "src/contexts/global/GlobalContext";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import {
  MS_TEAMS_CHAT_LINK,
  contactDetailsCopy,
  getContactDetailsConfig,
} from "./utils";

const StyledButton = styled(Button)`
  width: 28px;
  height: 28px;
`;

const Tooltip = styled(Popover)`
  .popoverText {
    background: black;
    color: white;
    border-radius: 5px;
    border-color: transparent;
    display: flex;
    justify-content: center;
    &:before {
      border-right-color: black;
    }
  }
`;

const PersonContactDetails = ({ person, tooltipDirection = "top" }) => {
  const globalConfig = useGlobalConfig();

  const globalContactConfig = getContactDetailsConfig(globalConfig);

  const [notification, setNotification] = useState(null);

  const [showCopiedState, setShowCopiedState] = useState(false);

  const phone =
    getPersonMobileNumberAttribute(person) ||
    getPersonTelephoneAttribute(person);

  const email = getPersonEmailAttribute(person);

  const getAriaLabel = (key, enabled) =>
    enabled
      ? contactDetailsCopy[key].available
      : contactDetailsCopy[key].unavailable;

  const contactDetailsConfig = [
    {
      icon: icons.mobile,
      visible: globalContactConfig.phone,
      disabled: !phone,
      ariaLabel: `${getAriaLabel("phone", !!phone?.value)}${
        phone?.value ? `: ${phone.value}` : ""
      }`,
      onClick: (event) => {
        const onError = () => {
          setNotification({
            message: contactDetailsCopy.phone.errorMessage,
            status: "danger",
          });
        };
        onPersonTelephoneClick(person, onError)(event);
        trackEvent(EVENT_TRACKING.PERSON_CONTACTED, {
          contact_type: "phone",
        });
      },
    },
    {
      icon: showCopiedState ? icons.copied : icons.email,
      visible: globalContactConfig.email,
      disabled: !email?.value,
      ariaLabel: showCopiedState
        ? contactDetailsCopy.email.successMessage
        : `${getAriaLabel("email", !!email?.value)}${
            email?.value ? `: ${email.value}` : ""
          }`,
      onClick: (event) => {
        onPersonEmailClick(person)(event);
        setShowCopiedState(true);
        setTimeout(() => {
          setShowCopiedState(false);
          setNotification(null);
        }, 3000);
        setNotification({
          message: contactDetailsCopy.email.successMessage,
          status: "success",
        });
        trackEvent(EVENT_TRACKING.PERSON_CONTACTED, {
          contact_type: "email",
        });
      },
    },
    {
      icon: icons.microsoftTeams,
      visible: globalContactConfig.microsoftTeams,
      disabled: !email?.value,
      ariaLabel: getAriaLabel("microsoftTeams", email?.value),
      onClick: () => {
        window.open(`${MS_TEAMS_CHAT_LINK}users=${email?.value}`);
        trackEvent(EVENT_TRACKING.PERSON_CONTACTED, {
          contact_type: "microsoft_teams",
        });
      },
    },
  ];

  return (
    <>
      <Flex>
        <Spacer ml="s">
          {contactDetailsConfig
            .filter((detail) => detail.visible)
            .map((detail) => (
              <Tooltip
                direction={tooltipDirection}
                key={detail.ariaLabel}
                text={detail.ariaLabel}
                width="fit-content"
              >
                <StyledButton
                  fullWidth
                  disabled={detail.disabled}
                  iconOnly
                  p="s"
                  ariaLabel={detail.ariaLabel}
                  onClick={detail.onClick}
                  variant="ghost"
                >
                  <Icon icon={detail.icon} />
                </StyledButton>
              </Tooltip>
            ))}
        </Spacer>
      </Flex>
      {notification && (
        <Notification
          colour={notification.status}
          onDismiss={() => setNotification(null)}
          centered
          floating
          closable={!showCopiedState}
          bottom="20px"
        >
          {notification.message}
        </Notification>
      )}
    </>
  );
};

PersonContactDetails.propTypes = {
  person: PersonPropType,
  tooltipDirection: PropTypes.string,
};

export default PersonContactDetails;
