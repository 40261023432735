import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Restricted from "src/app/Restricted";
import PATHS, { url } from "src/consts/urlPaths";

const SummaryLink = ({ children, item }) => (
  <Restricted>
    <Link id="summary-nav-link" to={url(PATHS.INSIGHTS)} aria-label={item.name}>
      {children}
    </Link>
  </Restricted>
);

SummaryLink.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
};

export default SummaryLink;
