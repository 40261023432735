/* eslint no-param-reassign: "error" */

import {
  forEach,
  get,
  keyBy,
  reject,
  remove,
  size,
  values,
  find,
  partition,
} from "lodash";
import {
  buildCellLookup,
  buildGroupingLookup,
  buildLineItemLookup,
  findGroupingFromParentIds,
  hashGrouping,
  newGrouping,
  newGroupingOptions,
  setCalculatedSumTotals,
  setGroupingsWithPlannerCells,
  setNotifications,
} from "./util";
import setDemandGroupingsWithSupplyLineItems from "./buildInitialModel/setLineItems/supplyLineItems";

const zeroBaselineMovedInGroupingCells = (grouping) => {
  forEach(grouping.lineItems, (li) => {
    const [baselineCell, requestorCell, approverCell] = li.cells;
    baselineCell.value = 0;
    requestorCell.defaultedValue = 0;
    // requestorCell.realtimeCurrentMemberFte = 0;
    requestorCell.delta = requestorCell.value;
    approverCell.defaultedValue = 0;
    // approverCell.realtimeCurrentMemberFte = 0;
    approverCell.delta = approverCell.value;
  });

  forEach(grouping.childGroupings, (cg) => {
    zeroBaselineMovedInGroupingCells(cg);
  });
};

const updateModelWithBaselineDiff = (
  model,
  payload,
  {
    groupTypes,
    isBudgetEnabled,
    showObjectives,
    useDeltaForBudget,
    isRealtimeFteMode,
    useRealtimeChangeOnly,
    useRealtimeFteDelta,
    rootGroup,
  }
) => {
  const {
    columns,
    groupings,
    lookups: { groupingLookup, allocationProjectLookup },
  } = model;

  const baselineGroups = get(payload, "data.baselineDescendantGroups");
  const baselineGroupLookup = keyBy(baselineGroups, "id");
  const rootGrouping = groupings[0];
  const activeAllocationProject = find(
    values(allocationProjectLookup),
    (ap) => ap.isActive
  );

  let baselineGroupsYetToParse = values(baselineGroupLookup);
  let baselineGroupsToParse = [];

  // process baseline groups from highest hierarchy level to lowest
  for (let hierarchyLevel = 0; hierarchyLevel < 999; hierarchyLevel += 1) {
    [baselineGroupsToParse, baselineGroupsYetToParse] = partition(
      baselineGroupsYetToParse,
      (g) => size(g.parentIds) === hierarchyLevel
    );

    forEach(baselineGroupsToParse, (baselineGroup) => {
      const grouping = groupingLookup[baselineGroup.id];
      if (grouping) {
        // baseline group still within the planner view
        if (grouping.group.directParentId === baselineGroup.directParentId) {
          // no diff detected
          // no-op
          return; // next baselineGroup please
        }

        // move in/out detected.
        // we have both the baselineGroup and the current group
        // but their directParentId's are different
        if (!groupingLookup[grouping.group.directParentId]) {
          // cannot find the current group parent within the planner view
          // so it's probably been fully moved out of the planner
          // note: this is an edge case, I've yet to see this happen

          // flag the grouping with a moveOut
          // also zero the request and approver values and make them non-editable
          grouping.isMovedOut = true;
          forEach(grouping.lineItems, (li) => {
            const [, requestorCell, approverCell] = li.cells;
            requestorCell.value = null;
            requestorCell.defaultedValue = null;
            requestorCell.isEditable = false;
            requestorCell.delta = null;
            approverCell.value = null;
            approverCell.defaultedValue = null;
            approverCell.isEditable = false;
            approverCell.delta = null;
          });
          if (groupingLookup[baselineGroup.directParentId]) {
            // but baseline parent still exists in the planner view
            // ok so we put the grouping back to it's baseline parent where it makes sense
            // we do this as because probably been put on the rootGrouping
            // the model puts groups on the rootGrouping when it cannot find its parent in the tree

            const newParent = groupingLookup[baselineGroup.directParentId];
            const oldParent = grouping.parent;
            remove(oldParent.childGroupings, (g) => g.id === grouping.id);
            grouping.parent = newParent;
            newParent.childGroupings.push(grouping);
          }
        } else if (!groupingLookup[baselineGroup.directParentId]) {
          // cannot find baseline group parent within the planner view
          // therefore its been fully moved into the picture

          // flag the grouping with a moveIn also zero the baseline values
          grouping.isMovedIn = true;
          grouping.movedParent = baselineGroup.directParent;
          zeroBaselineMovedInGroupingCells(grouping);
        } else {
          // a move, but found both baselineGroup parent & currentGroup parent are within the same planner view
          // probably a move between 2 groups within the the 1 view, no need to annotate that

          const parentGrouping =
            findGroupingFromParentIds(
              rootGrouping.childGroupings,
              baselineGroup.parentIds
            ) || rootGrouping;
          // take a copy of movedIn grouping and modify it to be movedOut with different parent
          const movedOutGrouping = {
            ...grouping,
            id: `${grouping.id}-virtual-moved-out-grouping`,
            canAddLineItem: false,
            canViewInTeamBuilder: false,
            canViewInDetails: true,
            isMovedOut: true,
            isMovedInternally: true,
            isMovedIn: false,
            parentGrouping,
            movedParent: grouping.parent?.group,

            // take a copy of the arrays so we don't mutate the original
            summedTotals: [...grouping.summedTotals],
            lineItems: [...grouping.lineItems],
            childGroupings: [],
            lineItemTotals: [...grouping.lineItemTotals],
            notifications: [...grouping.notifications],
            lineItemNotifications: [...grouping.lineItemNotifications],
          };

          movedOutGrouping.parentGrouping.childGroupings.push(movedOutGrouping);
          groupingLookup[movedOutGrouping.id] = movedOutGrouping;

          // set up the found grouping as moved in
          grouping.isMovedInternally = true;
          grouping.isMovedIn = true;
          grouping.movedParent = baselineGroup.directParent;
          zeroBaselineMovedInGroupingCells(grouping);
        }
      } else {
        // move out detected
        // we cannot find the currentGroup where a baseline exists
        // this means its been fully moved out of the picture with no trace within planner view
        // lets add the grouping back in and flag it as isMovedOut
        const macroAllocations = get(
          payload,
          `data.macroAllocationsForGroup_${baselineGroup.id}`,
          []
        );

        const parentGrouping =
          findGroupingFromParentIds(
            rootGrouping.childGroupings,
            baselineGroup.parentIds
          ) || rootGrouping;
        const movedOutGrouping = newGrouping({
          ...newGroupingOptions({
            group: baselineGroup,
            groupType: groupTypes[baselineGroup.type],
            parentGrouping,
            activeAllocationProject,
            isBudgetEnabled,
            sourceGroupLookup: null,
          }),
          id: `${baselineGroup.id}-virtual-moved-out-grouping`,
          parentGrouping,
          rootGrouping,
          nestedLevel: parentGrouping.nestedLevel + 1,
          canAddLineItem: false,
          canViewInTeamBuilder: false,
          canViewInDetails: true,
          showObjectives,
          columnCount: size(columns),
          isMovedOut: true,
          movedParent: get(
            find(get(payload, "data.parentsForMovedOutGroups"), {
              id: baselineGroup.id,
            }),
            "directParent"
          ),
        });
        setDemandGroupingsWithSupplyLineItems({
          groupings: [movedOutGrouping],
          macroAllocations,
          columnCount: columns.length,
          groupTypes,
          canRename: false,
          isBudgetEnabled: false,
          sourceGroupLookup: {},
        });
        setGroupingsWithPlannerCells({
          groupings: [movedOutGrouping],
          macroAllocations,
          allocationProjectLookup,
          isRequestor: !rootGroup.isSource,
          columns,
          groupedByDemand: true,
          rootGroup,
          useDeltaForBudget,
          isRealtimeFteMode: rootGroup.isDemand && isRealtimeFteMode,
          useRealtimeChangeOnly: rootGroup.isDemand && useRealtimeChangeOnly,
          useRealtimeFteDelta: rootGroup.isDemand && useRealtimeFteDelta,
        });
        parentGrouping.childGroupings.push(movedOutGrouping);
        groupingLookup[movedOutGrouping.id] = movedOutGrouping;
      }
    });
  }

  // find any extra move ins, excluding root group
  const groupingsExcludingRoot = reject(values(groupingLookup), rootGrouping);
  forEach(groupingsExcludingRoot, (grouping) => {
    if (!baselineGroupLookup[grouping.groupId]) {
      // move in detected
      // a current group exists where it does not within the baselineGroups.
      // therefore it's been fully moved into the picture
      grouping.movedParent = get(
        find(get(payload, "data.baselineParentsForMovedInGroups"), {
          id: grouping.group.id,
        }),
        "directParent"
      );
      grouping.isMovedIn = true;
      zeroBaselineMovedInGroupingCells(grouping);
    }
  });

  setCalculatedSumTotals([rootGrouping], columns);
  setNotifications([rootGrouping], columns);
  model.lookups.groupingLookup = buildGroupingLookup([rootGrouping]);
  model.lookups.lineItemLookup = buildLineItemLookup(
    values(model.lookups.groupingLookup)
  );
  model.lookups.cellLookup = buildCellLookup(
    values(model.lookups.lineItemLookup)
  );
  hashGrouping(rootGrouping, { rehashChildren: true });

  return model;
};

export default updateModelWithBaselineDiff;
