import {
  isTeamPage,
  isTeamDetailsPage,
  isTeamForecastPage,
  isTeamPlannerPage,
  isSummaryPage,
} from "src/util/url";
import { matchPaths } from "../consts/urlPaths";

const useTourForRouteMatch = () => {
  const match = matchPaths();

  if (isTeamPage(match?.path) && isTeamDetailsPage(match?.params)) {
    return "teamDetailsTour";
  }

  if (isTeamPage(match?.path) && isTeamForecastPage(match?.params)) {
    return "allocationForecastTour";
  }

  if (isTeamPage(match?.path) && isTeamPlannerPage(match?.params)) {
    return "allocationPlannerTour";
  }

  if (isSummaryPage(match?.path)) {
    return "filterSidebarTour";
  }

  return null;
};

export default useTourForRouteMatch;
