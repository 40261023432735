import convertFilterItems from "./util/convertFilterItems";
import splitSearchTerms from "./util/splitSearchTerms";

import * as ACTIONS from "./actionTypes";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.QUERY_COMPLETE: {
      const { data } = action;
      return {
        ...state,
        query: {
          ...state.query,
          loading: false,
          data,
        },
        filterItems: convertFilterItems(data),
      };
    }
    case ACTIONS.QUERY_ERROR: {
      const { error } = action;
      return {
        ...state,
        query: {
          ...state.query,
          loading: false,
          error,
        },
      };
    }

    case ACTIONS.SELECT_SIDEBAR_TAB: {
      const { selectedTab } = action;
      return {
        ...state,
        selectedTab: selectedTab || state.selectedTab,
      };
    }
    case ACTIONS.FILTER_ITEM_USER_SEARCH: {
      const { key, value } = action;

      const searchTerms = splitSearchTerms(value);
      return {
        ...state,
        filterItemSearch: {
          key,
          searchTerms,
        },
        query: {
          ...state.query,
          variables: {
            ...state.query.variables,
            searchTermsKey: key,
            searchTermsValues: searchTerms,
          },
          loading: true,
        },
      };
    }
    default:
      return state;
  }
};
