import { filter } from "lodash";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";

/**
 * This can be removed once the insights feature fully developed
 */
const useFilterNavItems = (navItems) => {
  const { enableStrategyInsightPanel } = useWorkspaceFeatureFlags();

  if (!enableStrategyInsightPanel) {
    return filter(navItems, (item) => item.name !== "Team alignment");
  }

  return navItems;
};

export default useFilterNavItems;
