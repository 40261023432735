import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React from "react";
import { Flex, StatusDot, Popover, Small, Spacer } from "orcs-design-system";
import { withRouter } from "react-router";
import { teamDetailsPath } from "../../util/routing";

const htmlMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

const badgeDetailsMap = {
  TRUE: {
    colour: "success",
    text: "MET",
  },
  FALSE: {
    colour: "danger",
    text: "NOT MET",
  },
  INSUFFICIENT_DATA: {
    colour: "warning",
    text: "N/A",
  },
};

const principleStatusText = (principle, showRuleDetail = false) => {
  return (
    <>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={htmlMarkup(principle?.title)}
      />
      <Small fontWeight="bold">
        STATUS : {badgeDetailsMap[principle.met].text}
      </Small>
      {showRuleDetail && principle.met === "FALSE" && principle.ruleDetail && (
        <>
          <br />
          <Small fontWeight="bold">
            REASON :{" "}
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={htmlMarkup(principle.ruleDetail)}
            />
          </Small>
        </>
      )}
    </>
  );
};

const PrincipleStatus = ({ team, history, textDirection, marginTop }) => {
  const statusDots = [];
  if (team && team.principles) {
    team.principles.forEach((principle) => {
      switch (principle.met) {
        case "TRUE":
          statusDots.push(
            <Popover
              key={team.id + principle.title}
              direction={textDirection || "bottomRight"}
              text={principleStatusText(principle)}
              textAlign="left"
              width="300px"
            >
              <StatusDot
                variant="success"
                onClick={() =>
                  history.push(teamDetailsPath(team, "principles"))
                }
              />
            </Popover>
          );
          break;

        case "FALSE":
          statusDots.push(
            <Popover
              key={team.id + principle.title}
              direction={textDirection || "bottomRight"}
              text={principleStatusText(principle)}
              textAlign="left"
              width="300px"
            >
              <StatusDot
                variant="danger"
                onClick={() =>
                  history.push(teamDetailsPath(team, "principles"))
                }
              />
            </Popover>
          );
          break;

        default:
          statusDots.push(
            <Popover
              key={team.id + principle.title}
              direction={textDirection || "bottomRight"}
              text={principleStatusText(principle)}
              textAlign="left"
              width="300px"
            >
              <StatusDot
                variant="warning"
                onClick={() =>
                  history.push(teamDetailsPath(team, "principles"))
                }
              />
            </Popover>
          );
      }
    });
  }

  return statusDots.length > 0 ? (
    <Flex alignItems="center" mt={marginTop}>
      <Spacer mx="xxs">{statusDots}</Spacer>
    </Flex>
  ) : null;
};

// TODO: Fix team def, object
PrincipleStatus.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string,
    principles: PropTypes.array,
  }),
  history: PropTypes.object,
  textDirection: PropTypes.string,
  marginTop: PropTypes.string,
};

export { principleStatusText };

export default withRouter(PrincipleStatus);
