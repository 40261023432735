import { isNil } from "lodash";
import { calculateTotalCost } from "src/allocation/util/budgetAndCost";
import { PENDING } from "../../../../../saveStatus";
import { PLANNER_REQUESTED } from "../../../../../columnTypes";
import {
  newNotification,
  newPlannerRequestorCellNotification,
} from "../../../newNotification";
import { calculateCellDelta } from "../../../cellDelta";

import newCell from "../../newCell";
import getFte from "./getFte";

const getPrepopulatedValueFromForecast = (
  forecastedAt,
  requestSavedAt,
  forecast,
  value
) => {
  // we have a forecasted value, without saving anything yet, and it's different from original
  if (forecastedAt && !requestSavedAt && forecast !== value) {
    return [forecast, PENDING];
  }

  return [null, null];
};

const getTargetValue = (useDeltaForBudget, delta, prepopulatedValue, value) => {
  if (useDeltaForBudget) {
    return delta;
  }

  return isNil(prepopulatedValue) ? value : prepopulatedValue;
};

export default ({
  lineItem,
  macroAllocation,
  allocationProject,
  isRequestor,
  isEditable,
  useDeltaForBudget,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
}) => {
  const {
    id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    requested,
    requestSavedAt,
    requestSubmitted,
    allocatedMemberFte, // current fte
    forecast,
    forecastedAt,
    note, // skills
    realtimeCurrentMemberFte: realtimeFte,
  } = macroAllocation;
  const { workingDays, disablePlannerRequirementInput } = allocationProject;
  const { dailyRate, isSkillsMandatory, grouping } = lineItem;
  const { isMovedOut } = grouping;

  const fte = useRealtimeChangeOnly
    ? realtimeFte
    : isRequestor
    ? requested
    : requestSubmitted; // approver only sees submitted

  let value = 0;
  let defaultedValue = 0;
  let realtimeCurrentMemberFte = 0;
  let delta = 0;
  let notification = newNotification({
    status: null,
    message: null,
    groupMessage: null,
    columnMessage: null,
  });

  if (grouping.isMovedOut) {
    value = 0;
    defaultedValue = getFte(allocatedMemberFte) || 0;
    realtimeCurrentMemberFte = getFte(
      isNil(realtimeFte) ? defaultedValue : realtimeFte
    );
    delta = calculateCellDelta({
      isRealtimeFteMode,
      useRealtimeChangeOnly,
      useRealtimeFteDelta,
      value,
      realtimeCurrentMemberFte,
      defaultedValue,
    });
  } else {
    value = getFte(fte);
    defaultedValue = getFte(allocatedMemberFte) || 0;
    realtimeCurrentMemberFte = getFte(
      isNil(realtimeFte) ? defaultedValue : realtimeFte
    );
    delta = calculateCellDelta({
      isRealtimeFteMode,
      useRealtimeChangeOnly,
      useRealtimeFteDelta,
      value,
      realtimeCurrentMemberFte,
      defaultedValue,
    });
    notification = newPlannerRequestorCellNotification(
      macroAllocation,
      isRequestor,
      isSkillsMandatory
    );
  }

  const [prepopulatedValue, valueSaveStatus] = getPrepopulatedValueFromForecast(
    forecastedAt,
    requestSavedAt,
    forecast,
    value
  );

  // Calculate totalCost with values by flag
  const totalCost = calculateTotalCost({
    value: getTargetValue(useDeltaForBudget, delta, prepopulatedValue, value),
    dailyRate,
    workingDays,
  });

  return newCell({
    lineItem,
    id: `${id}-${PLANNER_REQUESTED}`,
    columnType: PLANNER_REQUESTED,
    macroAllocationId: id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    isStatic: false,
    isEditable: !isMovedOut && isEditable,
    value,
    defaultedValue,
    prepopulatedValue,
    valueSaveStatus,
    delta,
    skills: note,
    isRequirementInputEnabled: !disablePlannerRequirementInput,
    notification,
    totalCost,
    dailyRate,
    workingDays,
    realtimeCurrentMemberFte,
  });
};
