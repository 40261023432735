/* eslint-disable react/prop-types */
import { get } from "lodash";
import { Box, Flex, FlexItem, Button, Spacer, P } from "orcs-design-system";
import React from "react";

import TagBadge from "src/components/Badge/TagBadge";

import { TAG_OPERATIONS } from "src/consts/tags";
import { shouldHighlightTag } from "../utils/shouldHighlightTag";
import TagTypeHelpButton from "./TagTypeHelpButton";

const actionButtonText = (option) => {
  if (
    get(option, "data.tag.tagTypeConfig.allowMultipleOnEntity") ||
    get(option, "existingTagsOfType") === 0
  ) {
    return "Add";
  }

  return "Replace";
};

const formatOptionLabel = (
  option,
  {
    context,
    showTagTypeHelp,
    onRemoveTag,
    onEditTag,
    onAddTag,
    onTagClick,
    tagsUpdating = {},
    entityType,
  }
) => {
  const { data } = option;
  if (!data) {
    return null;
  }

  const {
    tag: { tagTypeConfig, ...tag },
  } = data;

  const {
    name: tagTypeName,
    allowedToEditTagDisplayValue,
    isReadonly,
  } = tagTypeConfig;
  const getTypeName = () => tagTypeName;

  if (context === "value") {
    const isEditable = !isReadonly && allowedToEditTagDisplayValue;

    const onRemove = (e) => {
      e.stopPropagation();
      onRemoveTag(tag, tagTypeConfig);
    };

    const onEdit = (e) => {
      e.stopPropagation();
      onEditTag(tag, tagTypeConfig);
    };

    const onSelect = (e) => {
      e.stopPropagation();
      onTagClick(tag, tagTypeConfig);
    };

    const shouldHightlight = shouldHighlightTag({
      entityType,
      tag: {
        tagTypeConfig,
      },
    });

    const showUpdating = get(tagsUpdating, `[${tag?.id}]`, false);
    if (tag.status === TAG_OPERATIONS.REMOVED) {
      return (
        <TagBadge
          value={tag}
          getTypeName={getTypeName}
          withType
          selected={false}
          disabled
          isRemoved
          highlighted={shouldHightlight}
          tagTypeConfig={tagTypeConfig}
        />
      );
    }

    return (
      <TagBadge
        value={tag}
        getTypeName={getTypeName}
        withType
        selected
        onSelect={onSelect}
        showEdit={isEditable}
        showRemove={!isReadonly}
        onEdit={onEdit}
        onRemove={onRemove}
        showUpdating={showUpdating}
        highlighted={shouldHightlight}
        tagTypeConfig={tagTypeConfig}
      />
    );
  }

  if (tag.isUserCreatedTagValue) {
    return (
      <Flex justifyContent="space-between" alignItems="center">
        <FlexItem flexGrow="1" mr="s">
          <P>{`Create new ${tagTypeName} tag`}</P>
        </FlexItem>
        <Spacer ml="s">
          {tagTypeConfig.hasDetails && (
            <TagTypeHelpButton
              tagTypeConfig={tagTypeConfig}
              displayTagTypeHelp={showTagTypeHelp}
            />
          )}
          <Button small variant="success">
            Create
          </Button>
        </Spacer>
      </Flex>
    );
  }

  const onSelect = (e) => {
    e.stopPropagation();
    onAddTag(tag, tagTypeConfig);
  };

  return (
    <Box bg="transparent" width="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <FlexItem flexGrow="1" mr="s" onClick={onSelect}>
          <Flex alignItems="center">
            <TagBadge
              value={tag}
              getTypeName={getTypeName}
              tagTypeConfig={tagTypeConfig}
              withType
            />
          </Flex>
        </FlexItem>
        <Spacer ml="s">
          {tagTypeConfig.hasDetails && (
            <TagTypeHelpButton
              tagTypeConfig={tagTypeConfig}
              displayTagTypeHelp={showTagTypeHelp}
            />
          )}
          <Button small>{actionButtonText(option)}</Button>
        </Spacer>
      </Flex>
    </Box>
  );
};

export default formatOptionLabel;
