import { flatMap, get } from "lodash";

export const getGroupType = (group, groupTypes) => {
  if (!group) return null;
  return get(groupTypes, group.type, {
    name: group.type,
  });
};

export const isSupportedGroupType = (groupType) => {
  if (groupType) {
    return (
      groupType.isSupply ||
      groupType.isDemand ||
      groupType.isRoot ||
      groupType.isDirectoryVisible
    );
  }
  return false;
};

export const getRootGroupTypeIds = (groupTypes) =>
  flatMap(groupTypes, (groupType) => {
    return get(groupType, "isRoot") ? get(groupType, "id") : [];
  });

export const groupTypeAsText = (groupType) => {
  return groupType?.displayName || groupType?.name;
};
