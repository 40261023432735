import defaults from "./defaults";
import sidebarIconMiniTour from "./sidebarIconMiniTour";
import teamDetailsTour from "./teamDetailsTour";
import allocationForecastTour from "./allocationForecastTour";
import allocationForecastLineItemTour from "./allocationForecastLineItemTour";
import allocationPlannerTour from "./allocationPlannerTour";
import allocationPlannerLineItemTour from "./allocationPlannerLineItemTour";
import filterSidebarTour from "./filterSidebarTour";

const tours = {
  defaults,
  sidebarIconMiniTour,
  teamDetailsTour,
  allocationForecastTour,
  allocationForecastLineItemTour,
  allocationPlannerTour,
  allocationPlannerLineItemTour,
  filterSidebarTour,
};

export default tours;
