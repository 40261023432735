import { matchPath } from "react-router";
import PATHS, { TEAM_TAB } from "src/consts/urlPaths";

export const isVisualisationPage = (currentPath) =>
  currentPath?.startsWith(PATHS.VISUALISATION);

export const isSummaryPage = (currentPath) => PATHS.INSIGHTS === currentPath;

export const isSearchPage = (currentPath) => PATHS.SEARCH === currentPath;

export const isPeoplePage = (currentPath) => PATHS.PEOPLE === currentPath;

export const hostName = window.location.host.toLowerCase();

export const isTeamPage = (currentPath) => PATHS.TEAM_DETAILS === currentPath;

export const isTeamDetailsPage = (params) =>
  params.tabType === TEAM_TAB.DETAILS;

export const isTeamForecastPage = (params) =>
  params.tabType === TEAM_TAB.FORECAST;

export const isTeamPlannerPage = (params) =>
  params.tabType === TEAM_TAB.REQUEST_ALLOCATION_NUMBERS ||
  params.tabType === TEAM_TAB.APPROVE_ALLOCATION_NUMBERS ||
  params.tabType === TEAM_TAB.PLANNER;

export const isTeamBuilderPage = (params) =>
  params.tabType === TEAM_TAB.INDIVIDUAL_ALLOCATIONS ||
  params.tabType === TEAM_TAB.REQUEST_ALLOCATION_PEOPLE ||
  params.tabType === TEAM_TAB.APPROVE_ALLOCATION_PEOPLE ||
  params.tabType === TEAM_TAB.INDIVIDUAL_ALLOCATIONS_PERSON;

export const isTeamAllocationsPage = (urlMatch) => {
  if (!urlMatch || !isTeamPage(urlMatch.path)) return false;
  if (
    isTeamForecastPage(urlMatch.params) ||
    isTeamPlannerPage(urlMatch.params) ||
    isTeamBuilderPage(urlMatch.params)
  ) {
    return true;
  }
  return false;
};

export const isStrategyPage = (params) => {
  return params?.tabType === TEAM_TAB.STRATEGY && params?.id;
};

export const reloadPage = (isForceReload = false) =>
  window.location.reload(isForceReload);

// appends https if url doesn't contain protocol
export const fixUrlProtocol = (url) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    return `https://${url}`;
  }
  return url;
};

export const getUrlParameters = (url) =>
  url
    .substring(url.indexOf("?") + 1) // a=b454&c=dhjjh&f=g6hksdfjlksd
    .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
    .reduce(
      // reduce to // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
      // returns // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
      (memo, param) => ({
        ...memo,
        [param.split("=")[0]]: param.split("=")[1],
      }),
      {}
    );

// taken from https://gist.github.com/dperini/729294
// context https://mathiasbynens.be/demo/url-regex
export const isValidUrl = (url) => {
  // eslint-disable-next-line
  const reWebUrl = new RegExp(
    "^" +
      "(?:(?:(?:https?|ftp):)?\\/\\/)" +
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      "(?:" +
      "(?:" +
      "[a-z0-9\\u00a1-\\uffff]" +
      "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
      ")?" +
      "[a-z0-9\\u00a1-\\uffff]\\." +
      ")+" +
      "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
      ")" +
      "(?::\\d{2,5})?" +
      "(?:[/?#]\\S*)?" +
      "$",
    "i"
  );
  return reWebUrl.test(url);
};

const IgnoreErrorPages = [PATHS.DATASOURCE_CREATE];

export const shouldIgnoreError = () => {
  const { pathname } = window.location;

  for (let i = 0, j = IgnoreErrorPages.length; i < j; i += 1) {
    const route = IgnoreErrorPages[i];
    const match = matchPath(pathname, {
      path: route,
      exact: true,
      strict: false,
    });
    if (match) {
      return true;
    }
  }

  return false;
};

export const isOnGenericUrl = () =>
  process.env.REACT_APP_DOMAIN === window.location.origin;

export const genericPathname = (pathname) => {
  return pathname
    .replace(/\/$/, "")
    .replace(/\/[a-z0-9]{6}(?<!people|search)\//g, "/")
    .replace(/\/search\/all\/.+/g, "/search/all/<searchPhrase>")
    .replace(/\/search\/people\/.+/g, "/search/people/<searchPhrase>")
    .replace(/\/search\/teams\/.+/g, "/search/teams/<searchPhrase>")
    .replace(/\/search\/tags\/.+/g, "/search/tags/<searchPhrase>")
    .replace(/\/teams\/[A-Z0-9]{8}/g, "/teams/<teamId>")
    .replace(/\/people\/[a-zA-Z0-9\-_]+/g, "/people/<personId>")
    .replace(/\/tags\/[a-zA-Z0-9\-_]{21}/g, "/tags/<tagId>")
    .replace(
      /\/configuration\/datasource\/[a-zA-Z0-9\-_]{21}/g,
      "/configuration/datasource/<datasourceId>"
    )
    .replace(
      /\/visualisation\/team\/[A-Z0-9]{8}/g,
      "/visualisation/team/<teamId>"
    )
    .replace(
      /\/visualisation\/person\/[a-zA-Z0-9\-_]+/g,
      "/visualisation/person/<personId>"
    );
};
