import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import styled, { useTheme } from "styled-components";

import themeGet from "@styled-system/theme-get";

import { Button, Icon, Popover } from "orcs-design-system";
import SelectComponents from "src/components/SelectComponents";
import { OptionValuePropType } from "src/custom-prop-types/search";
import { getSearchPath } from "src/util/paths";

import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { placeholderText, reactSelectStyles } from "./Search.config";
import DropdownIndicator from "./sub-components/DropDownIndicator";
import GroupHeading from "./sub-components/GroupHeading";
import LoadingMessage from "./sub-components/LoadingMessage";
import Menu from "./sub-components/Menu";
import MenuList from "./sub-components/MenuList";
import NoOptionsMessage from "./sub-components/NoOptionsMessage";
import Option from "./sub-components/Option";
import { useGlobalSearchContext } from "./GlobalSearchContext";

const SearchWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SearchFilter = styled(Button)`
  background-color: ${themeGet("colors.white")};
  color: ${themeGet("colors.greyDark")};
  border: none;
  border-left: 1px solid ${themeGet("colors.grey")};
  border-radius: 0 ${themeGet("radii.2")} ${themeGet("radii.2")} 0;
  margin-right: 3px;
  font-size: ${themeGet("fontSizes.0")};
  height: 36px;
  margin-top: 1px;
  display: flex;
  align-items: center;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  &:hover,
  &:focus {
    box-shadow: none;
    background-color: ${themeGet("colors.white")};
    color: ${themeGet("colors.danger")};
    border: none;
    border-left: 1px solid ${themeGet("colors.grey")};
    svg,
    path {
      color: ${themeGet("colors.danger")};
    }
  }
`;

const SearchFilterTeamName = styled.span`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// TODO: Move this to ORCs when finalised
const Search = ({
  isMulti,
  isCloseOnSelect,
  isLoading,
  isStandardSelect,
  defaultMenuIsOpen = false,
  onOptionSelect,
  defaultOptions,
  loadOptions,
  searchError,
  groupTypes,
  featureFlags,
  workspace,
  showExploreLink = false,
  onDefaultScopeClearCallback,
  isForSearchPage = false,
}) => {
  const [paddingRight, setPaddingRight] = useState(0);
  const theme = useTheme();
  const history = useHistory();

  const overrideSelectStyles = reactSelectStyles(
    theme,
    paddingRight,
    isLoading
  );

  const {
    defaultSearchScope,
    setDefaultSearchScope,
    searchQuery,
    setSearchQuery,
    userSearchInputQuery,
    setUserSearchInputQuery,
  } = useGlobalSearchContext();

  const filterRef = useRef(null);
  const timeoutRef = useRef(null);
  const inputValue = userSearchInputQuery || "";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (filterRef.current) {
      setPaddingRight(filterRef.current.clientWidth);
    } else {
      setPaddingRight(0);
    }
  });

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const CustomOption = (props) => (
    <Option
      {...props}
      isLoading={isLoading}
      groupTypes={groupTypes}
      featureFlags={featureFlags}
      workspace={workspace}
      selectedString={searchQuery}
    />
  );

  const onChange = (value) => {
    if (onOptionSelect) {
      onOptionSelect(value);
    }
  };

  const customComponentProp = isStandardSelect
    ? {
        components: {
          ...SelectComponents,
          ClearIndicator: () => null,
        },
      }
    : {
        inputValue,
        value: inputValue,
        components: {
          ...SelectComponents,
          LoadingMessage,
          Option: CustomOption,
          DropdownIndicator,
          Menu: isForSearchPage ? () => null : Menu,
          MenuList,
        },
      };

  const onInputChange = useCallback(
    (query, { action }) => {
      if (action === "input-change") {
        setUserSearchInputQuery(query);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setSearchQuery(query);
        }, 600);
      }
      return query;
    },
    [setSearchQuery, setUserSearchInputQuery]
  );

  const onKeyDown = useCallback(
    ({ key }) => {
      if (!isStandardSelect && key === "Enter" && !isEmpty(searchQuery)) {
        const searchPath = getSearchPath(undefined, searchQuery);
        history.push(searchPath);
      }
    },
    [history, isStandardSelect, searchQuery]
  );

  const onDefaultScopeClear = () => {
    setDefaultSearchScope(null);
    onDefaultScopeClearCallback(searchQuery);
    trackEvent(EVENT_TRACKING.SEARCH_FILTERED, {
      setting: "scope",
      cleared: true,
    });
  };

  return (
    <form data-testid="cp-search-form">
      <SearchWrapper>
        <AsyncSelect
          name="global-search"
          inputId="global-search"
          cacheOptions={!searchError}
          closeMenuOnSelect={isCloseOnSelect}
          closeMenuOnScroll={false}
          defaultMenuIsOpen={defaultMenuIsOpen}
          hideSelectedOptions={true}
          placeholder={placeholderText}
          onInputChange={onInputChange}
          onChange={onChange}
          isLoading={isLoading}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          noOptionsMessage={() => (
            <NoOptionsMessage
              isStandardSelect={isStandardSelect}
              searchError={searchError}
              showExploreLink={showExploreLink}
            />
          )}
          onKeyDown={onKeyDown}
          formatGroupLabel={GroupHeading}
          styles={overrideSelectStyles}
          aria-label="Type to search for people, teams or tags"
          role="searchbox"
          isMulti={isMulti}
          {...customComponentProp}
          form="_none"
        />
        {!isEmpty(defaultSearchScope) && (
          <SearchFilter
            ref={filterRef}
            iconRight
            small
            variant="dangerAlternate"
            onClick={onDefaultScopeClear}
            height="36px"
          >
            <Popover
              text="Click to remove filter"
              direction="bottom"
              textAlign="center"
              width="140px"
            >
              <SearchFilterTeamName>
                in: {defaultSearchScope.name}
              </SearchFilterTeamName>
              <Icon icon={["fas", "times"]} color="greyDark" ml="xs" />
            </Popover>
          </SearchFilter>
        )}
      </SearchWrapper>
    </form>
  );
};

Search.propTypes = {
  defaultMenuIsOpen: PropTypes.bool,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.array, // empty array for testing
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        options: PropTypes.arrayOf(OptionValuePropType),
      })
    ),
  ]),
  isMulti: PropTypes.bool,
  isCloseOnSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  isStandardSelect: PropTypes.bool,
  onOptionSelect: PropTypes.func,
  loadOptions: PropTypes.func,
  searchError: PropTypes.object,
  groupTypes: PropTypes.object,
  featureFlags: PropTypes.object,
  workspace: PropTypes.object,
  showExploreLink: PropTypes.bool,
  onDefaultScopeClearCallback: PropTypes.func,
  isForSearchPage: PropTypes.bool,
};

export default Search;
