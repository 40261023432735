import { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "src/contexts/global/GlobalContext";

import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import tours from "./tours";
import tourDependenciesComplete from "./util/tourDependenciesComplete";

const useGuidedTourTrigger = () => {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();

  return useCallback(
    (tourName) => {
      if (!tours[tourName]) {
        // no tour found, don't try to trigger it
        return;
      }

      const tour = tours[tourName]({}); // no data here, we just want the dependsOn prop

      if (!tourDependenciesComplete(auth.username, tour.dependsOn)) {
        // tour has dependencies not yet completed
        return;
      }

      trackEvent(EVENT_TRACKING.GUIDED_TOUR_STARTED, {
        guided_tour: tourName,
      });

      const queryParams = new URLSearchParams(location.search);
      queryParams.set("guided_tour", tourName);
      history.push({
        search: `?${queryParams.toString()}`,
        state:
          tourName === "filterSidebarTour"
            ? {
                sidebar: { action: "openSidebar", selectedTab: "filter" },
              }
            : undefined,
      });
    },
    [location, history, auth]
  );
};

export default useGuidedTourTrigger;
