import moment from "moment";

import { PENDING } from "../../../../../saveStatus";
import { PLANNER_APPROVED } from "../../../../../columnTypes";
import {
  newNotification,
  newPlannerApproverCellNotification,
} from "../../../newNotification";
import { calculateDelta } from "../../../cellDelta";

import newCell from "../../newCell";
import getFte from "./getFte";

const getPrepopulatedValueFromRequestSubmitted = (
  requestSubmittedAt,
  approvalSavedAt,
  requestSubmitted,
  value
) => {
  // nothing submitted from requestor
  if (!requestSubmittedAt) {
    return [null, null];
  }

  // requestor submitted value, but already used
  if (requestSubmitted === value) {
    return [null, null];
  }

  // we have not approved anything yet, prepopulate with new request
  if (!approvalSavedAt) {
    return [requestSubmitted, PENDING];
  }

  // user has a new requestSubmitted value, but has already saved something
  // prepopulate with original saved value. This gives user ability to save original value again (responding to request)
  if (moment(requestSubmittedAt).isAfter(moment(approvalSavedAt))) {
    return [value, PENDING];
  }

  // nothing to pre-populate
  return [null, null];
};

export default ({
  lineItem,
  macroAllocation,
  isApprover,
  isEditable,
  approvalReasons,
}) => {
  const {
    id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    requestSubmitted,
    requestSubmittedAt,
    approved,
    approvalSubmitted,
    approvalSavedAt,
    allocatedMemberFte, // current fte
    note, // skills
    approvalReasonId,
    approvalReasonCustomText,
    realtimeCurrentMemberFte: realtimeFte,
  } = macroAllocation;

  const { grouping } = lineItem;
  const { isMovedOut } = grouping;

  const fte = isApprover ? approved : approvalSubmitted; // requestor only sees submitted
  let value = 0;
  let defaultedValue = 0;
  // let realtimeCurrentMemberFte = 0;
  let delta = 0;
  let notification = newNotification({
    status: null,
    message: null,
    groupMessage: null,
    columnMessage: null,
  });

  if (isMovedOut) {
    value = 0;
    defaultedValue = getFte(allocatedMemberFte) || 0;
    // realtimeCurrentMemberFte = 0;
    delta = -getFte(realtimeFte);
  } else {
    value = getFte(fte);
    defaultedValue = getFte(allocatedMemberFte) || 0;
    // realtimeCurrentMemberFte = getFte(
    //   isNil(realtimeFte) ? defaultedValue : realtimeFte
    // );
    delta = calculateDelta(value, defaultedValue);
    notification = newPlannerApproverCellNotification(
      macroAllocation,
      isApprover,
      approvalReasons
    );
  }
  const [prepopulatedValue, valueSaveStatus] =
    getPrepopulatedValueFromRequestSubmitted(
      requestSubmittedAt,
      approvalSavedAt,
      requestSubmitted,
      value
    );

  return newCell({
    lineItem,
    id: `${id}-${PLANNER_APPROVED}`,
    columnType: PLANNER_APPROVED,
    macroAllocationId: id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    isStatic: false,
    isEditable: !isMovedOut && isEditable,
    value,
    defaultedValue,
    prepopulatedValue,
    valueSaveStatus,
    delta,
    skills: note,
    notification,
    approvalReasonId,
    approvalReasonCustomText,
    requestSubmittedAt,
    approvalSavedAt,
  });
};
