import { get } from "lodash";
import { getTypeForGroup } from "src/util/customerConfig";

const getAllocationVisibility = ({
  group = {},
  config,
  activeAllocationProject,
}) => {
  const { isDeleted } = group;
  const isAllocationsClosed = !activeAllocationProject;
  const isDeniedAccess = !get(
    group,
    "permissions.areGroupsInAccessGroupIdsHierarchy"
  );
  if (isAllocationsClosed || isDeniedAccess || isDeleted) {
    return {
      requestNumbers2: false,
      requestPeople: false,
      approveNumbers2: false,
      individualAllocations: false,
      forecastNumbers: false,
      isAllocationsClosed,
      isDeniedAccess,
      isDeleted,
    };
  }
  const groupTypeConfig = getTypeForGroup(config.groupTypes, group);
  const { disableGroupPlanner, disableGroupForecast, disableGroupTeamBuilder } =
    config.allocation;

  const isRequestorType = groupTypeConfig?.isDemandRequestor ?? false;
  const isLineOfBusinessType = groupTypeConfig?.isLineOfBusiness ?? false;
  const isApproverType = groupTypeConfig?.isSupplyApprover ?? false;

  return {
    requestNumbers2:
      !disableGroupPlanner && (isRequestorType || isLineOfBusinessType),
    requestPeople: !disableGroupTeamBuilder && isRequestorType,
    approveNumbers2: !disableGroupPlanner && isApproverType,
    individualAllocations: !disableGroupTeamBuilder && isApproverType,
    forecastNumbers:
      !disableGroupForecast && (isRequestorType || isApproverType),
    isAllocationsClosed,
    isDeniedAccess,
  };
};

export default getAllocationVisibility;
