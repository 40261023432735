import { useEffect } from "react";

const useSideNavClickListener = () => {
  useEffect(() => {
    // Empty handler to prevent default collapse behavior
    const handler = () => {};

    window.addEventListener("click", handler, true);

    return () => {
      window.removeEventListener("click", handler, true);
    };
  }, []);
};

export default useSideNavClickListener;
