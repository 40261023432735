import { isEmpty, get, reject } from "lodash";
import {
  Button,
  Flex,
  Modal,
  H3,
  Box,
  Spacer,
  Small,
  Select,
  TextInput,
  Divider,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";

import { useCachedTag } from "src/hooks/useCachedTag";
import { useCachedGroup } from "src/hooks/useSearchGroups";
import { useCachedPerson } from "src/hooks/useSearchPeople";
import ErrorNotification from "src/components/ErrorNotification";

import { useTagFormValidator } from "src/hooks/useTagFormValidator";
import { RichTextContentViewer } from "src/shared/RichTextContent/RichTextContent";
import {
  buildDisplayValue,
  filterTagAttributeTypes,
} from "../TagAttributeModal.logic";
import TagAttributesFields from "../TagAttributesFields";

const DetailsWrapper = styled(Box)`
  overflow-y: auto;
  max-height: 150px;
`;

const EmptyAttrTypes = [];

const getTagTypeOptions = (allTags) => {
  return reject(allTags, (tagType) => tagType.isReadonly).map(
    ({ id, name }) => ({
      label: name || id,
      value: id,
    })
  );
};

const CreateNewTagModal = ({
  onClose,
  allTags,
  setNewTag,
  featureFlags,
  tagTypesConfig,
  initialDisplayValue,
  initialTagType,
  initialAttributes,
}) => {
  const [displayValue, setDisplayValue] = useState(initialDisplayValue || "");
  const [updatedAttributes, setUpdatedAttributes] = useState(
    initialAttributes || {}
  );
  const [selectedTag, setSelectedTag] = useState(initialTagType || null);

  const {
    onFieldUpdate,
    onChangeTagType,
    onFormSave,
    fieldToBeFocused,
    fieldsToShowInvalidity,
    clearFieldToBeFocused,
  } = useTagFormValidator({
    fields: {},
    tagType: selectedTag,
    tagValue: { attributes: updatedAttributes },
    includeEntityAttributes: true,
  });

  const isMounted = useRef(false);
  const { getCachedGroup } = useCachedGroup();
  const { getCachedPerson } = useCachedPerson();
  const { getCachedTag } = useCachedTag();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);

  const {
    hasDetails,
    detailsParsed: parsedDetails,
    attributes: meta = {},
  } = selectedTag || {};

  const {
    separator = "_",
    values: nameAttrTypes = EmptyAttrTypes,
    others: otherAttrTypes = EmptyAttrTypes,
    entityTag: entityTagAttrTypes = EmptyAttrTypes,
  } = meta || {};

  const [readonlyAttrTypes, editableAttrTypes, uniqueAttrTypes] =
    useMemo(() => {
      return filterTagAttributeTypes(
        nameAttrTypes,
        otherAttrTypes,
        entityTagAttrTypes
      );
    }, [entityTagAttrTypes, nameAttrTypes, otherAttrTypes]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    const combinedValue = buildDisplayValue({
      attrTypeValues: nameAttrTypes,
      attributes: updatedAttributes,
      separator,
      getCachedGroup,
      getCachedPerson,
      getCachedTag,
    });
    if (!isEmpty(combinedValue)) {
      setDisplayValue(combinedValue);
    }
  }, [
    nameAttrTypes,
    separator,
    updatedAttributes,
    getCachedGroup,
    getCachedPerson,
    getCachedTag,
  ]);

  const clearAll = () => {
    setUpdatedAttributes({});
    setDisplayValue("");
    setIsSaving(false);
    setError(null);
    setValidationError(null);
  };

  const onDisplayValueChange = (e) => {
    const newValue = e.target.value;
    setDisplayValue(newValue);
  };

  const onAttrValueChange = (type) => (e) => {
    const newValue = e.target.value;

    const newAttributes = {
      ...updatedAttributes,
      [type]: newValue,
    };

    clearFieldToBeFocused();
    onFieldUpdate(newAttributes);
    setUpdatedAttributes(newAttributes);
  };

  const onSelectAttrValue = (id) => (option) => {
    const newAttributes = {
      ...updatedAttributes,
      [id]: option ? option.value : undefined,
    };

    clearFieldToBeFocused();
    onFieldUpdate(newAttributes);
    setUpdatedAttributes(newAttributes);
  };

  const onDateChange = (id) => (value) => {
    const newAttributes = {
      ...updatedAttributes,
      [id]: value,
    };
    clearFieldToBeFocused();
    onFieldUpdate(newAttributes);
    setUpdatedAttributes(newAttributes);
  };

  const onSave = async () => {
    const invalidFields = onFormSave({ attributes: updatedAttributes });

    if (!isEmpty(invalidFields)) {
      return;
    }

    if (!displayValue) {
      setValidationError(new Error("Display value can not be empty"));
      return;
    }

    try {
      setIsSaving(true);
      await setNewTag(
        {
          displayValue,
          isUserCreatedTagValue: true,
          ...(!isEmpty(updatedAttributes) && { attributes: updatedAttributes }),
        },
        selectedTag
      );
      setIsSaving(false);
      onClose();
    } catch (e) {
      setError(e);
      setIsSaving(false);
    }
  };

  const handleTagTypeChange = ({ value }) => {
    clearAll();
    const selectedTagType = allTags.find((tagType) => tagType.id === value);
    setSelectedTag(selectedTagType);
    onChangeTagType(selectedTagType);
  };

  const getSelectedTagOption = () => {
    if (!selectedTag) {
      return null;
    }

    const { id, name } = selectedTag;
    return {
      value: id,
      label: name || id,
    };
  };

  const hasNoValueAttrs = isEmpty(get(selectedTag, "attributes.values"));

  const modalHeader = (
    <Flex px="xs">
      <Box width="50%">
        <H3>Tag details</H3>
      </Box>
      <Box width="50%">
        <H3 ml="19px">Tag attributes</H3>
      </Box>
    </Flex>
  );

  const modalFooter = (
    <Flex pl="4px">
      <Button
        onClick={onSave}
        isLoading={isSaving}
        disabled={isSaving}
        variant={isSaving ? "disabled" : "success"}
        mr="s"
      >
        Save
      </Button>
      <Button onClick={onClose} variant="ghost">
        Close
      </Button>
    </Flex>
  );

  return (
    <Modal
      width={["90vw", "90vw", "90vw", "80vw", "70vw", "50vw"]}
      height={["80vh", "80vh", "80vh", "80vh", "60vh"]}
      maxWidth="90vw"
      maxHeight="90vh"
      visible
      onClose={(e) => {
        if (e) onClose();
      }}
      headerContent={modalHeader}
      footerContent={modalFooter}
    >
      <Flex width="100%" p="xs" pt="r">
        <Box mr="r" width="50%">
          <Spacer mb="r">
            <Select
              inputId="tag-type-selector"
              dataTestId="tag-type-selector"
              label={
                isEmpty(selectedTag)
                  ? "Select what type of tag you would like to create:"
                  : "Tag type"
              }
              onChange={handleTagTypeChange}
              value={getSelectedTagOption()}
              options={getTagTypeOptions(allTags)}
            />
            {hasDetails ? (
              <>
                <Small mb="xs" display="block">
                  Tag type description
                </Small>
                <DetailsWrapper
                  p="s"
                  bg="greyLightest"
                  boxBorder="default"
                  borderRadius={2}
                >
                  <RichTextContentViewer
                    content={parsedDetails}
                    fontSize="14px"
                  />
                </DetailsWrapper>
              </>
            ) : (
              <TextInput
                fullWidth
                disabled
                label="Tag type description"
                placeholder="No description has been set for this tag type."
              />
            )}
            {!hasNoValueAttrs && (
              <TextInput
                name="tag-display-value"
                type="text"
                id="name"
                label="Tag display value"
                fullWidth
                value={displayValue}
                disabled
              />
            )}
            <TagAttributesFields
              attrTypeValues={readonlyAttrTypes}
              updatedAttributes={updatedAttributes}
              onAttrValueChange={onAttrValueChange}
              onSelectAttrValue={onSelectAttrValue}
              onDateChange={onDateChange}
              featureFlags={featureFlags}
              editMode
            />
          </Spacer>
        </Box>
        <Box width="50%">
          {hasNoValueAttrs && selectedTag && (
            <Box mb="r">
              <TextInput
                type="text"
                id="display-value-input"
                label="Tag display value"
                data-testid="displayValueInput"
                placeholder="Not set"
                fullWidth
                mandatory
                value={displayValue}
                maxLength={120}
                onChange={onDisplayValueChange}
              />
              {validationError && (
                <Small color="danger">{validationError.message}</Small>
              )}
            </Box>
          )}
          <TagAttributesFields
            attrTypeValues={editableAttrTypes}
            updatedAttributes={updatedAttributes}
            onAttrValueChange={onAttrValueChange}
            onSelectAttrValue={onSelectAttrValue}
            onDateChange={onDateChange}
            fieldToBeFocused={fieldToBeFocused}
            fieldsToShowInvalidity={fieldsToShowInvalidity}
            featureFlags={featureFlags}
            tagTypesConfig={tagTypesConfig}
            editMode
          />
        </Box>
        {error && <ErrorNotification message={error?.message} error={error} />}
      </Flex>
      {!isEmpty(uniqueAttrTypes) && (
        <Flex width="100%" p="xs" pt="r" flexDirection="column">
          <Divider light />
          <Box width="50%" mt="r">
            <Box mb="r">
              <H3>Unique attributes</H3>
              Note: Changing these will update these values only for this
              entity.
            </Box>
            <TagAttributesFields
              attrTypeValues={uniqueAttrTypes}
              updatedAttributes={updatedAttributes}
              onAttrValueChange={onAttrValueChange}
              onSelectAttrValue={onSelectAttrValue}
              onDateChange={onDateChange}
              fieldToBeFocused={fieldToBeFocused}
              fieldsToShowInvalidity={fieldsToShowInvalidity}
              featureFlags={featureFlags}
              tagTypesConfig={tagTypesConfig}
              editMode
            />
          </Box>
        </Flex>
      )}
    </Modal>
  );
};

CreateNewTagModal.propTypes = {
  onClose: PropTypes.func,
  setNewTag: PropTypes.func,
  allTags: PropTypes.array,
  featureFlags: PropTypes.object,
  tagTypesConfig: PropTypes.array,
  initialDisplayValue: PropTypes.string,
  initialTagType: PropTypes.object,
  initialAttributes: PropTypes.object,
};

export default CreateNewTagModal;
